/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.LoginAccount {
    &-InnerWrapper {
        @include desktop {
            max-width: 560px;
            display: flex;
            flex-direction: column;
            grid-gap: initial;
        }

        & > div {
            margin: 0;
        }

        .Form {
            display: flex;
            flex-direction: column;
            margin: 6px 0 0;

            @include desktop {
                margin: 12px 0 0;
            }
        }

        .MyAccountOverlay {
            &-SignInButton {
                .Button {
                    font-family: $font-HyundaiSansHeadKR;
                    margin: initial;
                }
            }

            &-ForgotPassword {
                align-self: end;
                margin: initial;
            }
        }
    }

    &-CreateAccount {
        h3 {
            font-size: 16px;
            margin-block-end: 0;
            font-family: $font-HyundaiSansTextKR;
            text-transform: initial;

            @include desktop {
                margin: initial;
            }
        }

        p {
            font-size: 14px;
            margin: initial;
        }

        &Button.Button {
            margin: 24px 0 0;
            font-family: $font-HyundaiSansHeadKR;
        }
    }

    &-WrapperHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            align-items: flex-end;
        }

        .RequiredMark {
            display: flex;
            justify-content: space-between;

            p {
                margin-block-end: 0;
                font-size: 14px;
                line-height: 20px;
            }

            span {
                padding-block-end: 0;
            }
        }
    }

    .ContentWrapper {
        padding-block: 24px 48px;
        padding-inline: 0;

        @include desktop {
            padding-block: 60px 108px;
        }

        @include mobile {
            padding-inline: 16px;
        }
    }

    &-SaveForgotWrapper {
        display: flex;
        justify-content: space-between;
        margin: 12px 0 36px;
        align-items: center;

        @include desktop {
            margin: 16px 0 45px;
        }
    }

    .Field {
        &_type_checkbox {
            margin: initial;
        }
    }

    .SocialLogin button {
        margin: initial;

        &:hover {
            background-color: initial;
            padding: 0 10px;
        }
    }

    &-QuickLoginText {
        font-size: 16px;
        margin-block-end: 0;
        margin-block-start: 0;
        margin-inline-start: 0;
        text-transform: unset;
    }

    &-QuickLoginTextWrapper {
        margin-block-start: 20px;
    }
}
