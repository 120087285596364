/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --my-account-tab-list-item-color: var(--primary-text-color);
    --my-account-tab-list-border-color: var(--stroke-color);
}

.MyAccountTabListItem {
    min-height: 0;
    
    &-Button {
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include desktop {
            padding: 0;
            min-height: 24px;
            min-width: 196px;
        }
    }
    
    &:first-child {
        @include desktop {
            padding: 60px 52px 8px;
        }
    }

    &:not(:first-of-type) {
        @include desktop {
            padding: 0 52px 28px;
        }
    }

    &-Border {
        @include mobile {
            border-block-end: 1px solid var(--my-account-tab-list-border-color);
            width: 90%;
            margin: 0 auto;
        }
    }
}
