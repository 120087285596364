/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable scss/comment-no-empty */

.MyAccount {
    margin-block-start: var(--header-height);
    min-height: 0;

    &-Heading {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        margin-block-end: 8px;
        align-items: center;

        @include mobile {
            font-size: 26px;
            margin-block-end: 0px;
            align-items: center;
            line-height: 32px;
            padding-block-start: 24px;
        }

        @include desktop {
            font-size: 40px;
            margin-block-end: 8px;
            align-items: baseline;
        }

        @include small-mobile {
            margin-block-end: 8px;
        }
    }

    &-RequiredLabel {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        font-family: $font-HyundaiSansTextKR;

        & span {
            padding: 0px;
        }
    }

    &-Wrapper {
        padding-inline-start: 0;

        @include desktop {
            grid-column-gap: 0;
            grid-template-rows: 1fr;
            padding-block: 0;
            margin-inline-start: 0;
            min-height: calc(100vh - #{$footerHeightDesktop} - var(--header-height));
        }
    }

    &-TabContent {
        border: none;
        max-width: 1022px;

        @include desktop {
            padding: 60px 86px 108px 52px;
        }

        &_activeTab_edit-profile {
            .FieldForm-Section {
                display: flex;
                flex-flow: column nowrap;

                @include desktop {
                    flex-flow: row wrap;
                    column-gap: 20px;
                }

                .Field-Wrapper {
                    @include desktop {
                        width: 432px;
                    }

                    &:first-child {
                        @include desktop {
                            // To create blank space
                            margin-inline-end: 10px;
                        }
                    }
                }
            }
        }
    }
}

/* stylelint-enable scss/comment-no-empty */
