/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CreateAccount-CreateAccountWrapper {
    .MyAccountOverlay {
        &-Legend {
            &:nth-child(3) {
                display: flex;
                flex-direction: column;
                padding: 4px 0 0;
                margin: 32px 0;
                border: 3px solid var(--color-black);
                border-left: initial;
                border-right: initial;
                border-bottom: initial;

                @include desktop {
                    margin: 40px 0;
                    padding: 8px 0 0;
                }

                .Field {
                    margin: initial;

                    &-Wrapper {
                        margin: 12px 0 0;
                    }
                }

                input {
                    height: 20px;
                }
            }

            &:nth-child(1) {
                .Field-Wrapper {
                    &:first-child {
                        @include desktop {
                            grid-column: 1 / span 2;
                        }

                        .Field {
                            @include desktop {
                                width: calc(50% - 10px);
                            }
                        }
                    }
                }
            }
        }

        &-PasswordBlock {
            @include desktop {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                justify-content: space-between;
                align-content: center;
            }
        }
    }

    &-Buttons {
        button {
            margin: initial;
        }
    }

    .Field {
        input,
        select {
            padding: 12px 44px 12px 20px;
            line-height: 24px;
        }

        input {
            padding-inline-end: 48px;
        }

        &_type_checkbox {
            span {
                line-height: 24px;
                padding-block-end: 0;
            }

            a {
                @include linkWithArrow;
            }
        }

        &-ErrorMessages {
            margin: 8px 0 0 0;
        }

        &-Password {
            .Field-ErrorMessages {
                margin: 12px 0 0;
            }
        }
    }

    .FieldSelect {
        .ChevronIcon {
            inset-inline-end: 21px;
        }
    }
}
