/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.MyAccountForgotPasswordPopup {
    .Popup {
        &-Content {
            @include desktop {
                width: 700px;
            }
    
            .ForgotPassword {
                &-InnerWrapper {
                    padding-block-start: 0;
                }
    
                &-WrapperHeader {
                    display: flex;
                    justify-content: space-between;
    
                    .RequiredMark {
                        justify-content: right;
    
                        p {
                            margin-inline-end: 5px;
                        }
                    }
                }
            }
        }
    }
}
